import React from "react";
import ReactDOM from "react-dom";
import EmployeeRegistration from "EmployeeRegistration";

document.addEventListener("DOMContentLoaded", () => {
  const mountNode = document.getElementById("employee-registration-root");
  if (mountNode) {
    ReactDOM.render(<EmployeeRegistration />, mountNode);
  }
});
